



















































import { postController } from '@/modules/common/dialogs/post/post-controller'
import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  postController = postController

  changeInputDialog(input) {
    if (!input) this.postController.changeOpenCompletedCreateDao(false)
  }
}
